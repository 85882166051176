.App {
  text-align: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.App {
  color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes comeLeft {

  0% {
    opacity: 0;
    transform: translateX(-500px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes comeRight{
  0% {
    opacity: 0;
    transform: translateX(500px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.primary-text {
  animation: comeLeft 1s ease-out 0s 1 forwards;
  z-index: 0;
}
  
.secondary-text {
  animation: comeRight 1s ease-out 0s 1 forwards;
  z-index: 0;
}

@media screen and (min-width:768px) {
  html {
    font-size: 18px;
  }

}

@media (min-width:1024px) {
  html {
    font-size: 19px;
  }
}

@media (min-width:1200px) {
  html {
    font-size: 20px;
  }
}

h1, h2, h3 {
  color: purple;
  text-transform: uppercase;
}

h2 {
  width: 100%;
  border-bottom: 1px solid purple ;
  text-align: left;
}

h1 {
  color: darkblue;
}

.navlink {
    text-decoration: none;
      color: purple;
      cursor: pointer;
      margin: auto 1rem auto ; 
}

.navlink:hover {
  text-decoration: underline;
}