body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
    overflow-x: hidden;
    box-sizing: border-box;
}
.App {
  text-align: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.App {
  color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes comeLeft {

  0% {
    opacity: 0;
    -webkit-transform: translateX(-500px);
            transform: translateX(-500px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes comeLeft {

  0% {
    opacity: 0;
    -webkit-transform: translateX(-500px);
            transform: translateX(-500px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes comeRight{
  0% {
    opacity: 0;
    -webkit-transform: translateX(500px);
            transform: translateX(500px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes comeRight{
  0% {
    opacity: 0;
    -webkit-transform: translateX(500px);
            transform: translateX(500px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.primary-text {
  -webkit-animation: comeLeft 1s ease-out 0s 1 forwards;
          animation: comeLeft 1s ease-out 0s 1 forwards;
  z-index: 0;
}
  
.secondary-text {
  -webkit-animation: comeRight 1s ease-out 0s 1 forwards;
          animation: comeRight 1s ease-out 0s 1 forwards;
  z-index: 0;
}

@media screen and (min-width:768px) {
  html {
    font-size: 18px;
  }

}

@media (min-width:1024px) {
  html {
    font-size: 19px;
  }
}

@media (min-width:1200px) {
  html {
    font-size: 20px;
  }
}

h1, h2, h3 {
  color: purple;
  text-transform: uppercase;
}

h2 {
  width: 100%;
  border-bottom: 1px solid purple ;
  text-align: left;
}

h1 {
  color: darkblue;
}

.navlink {
    text-decoration: none;
      color: purple;
      cursor: pointer;
      margin: auto 1rem auto ; 
}

.navlink:hover {
  text-decoration: underline;
}
